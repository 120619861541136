import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';
import Signal from 'ln/signal/Signal';

class OfferFilter extends View {

    public selected: Signal<string[]>;

    constructor(  ) {

		super(  );
        this.defaultTemplate = 'app.offer-filter';

        this.selected = new Signal<string[]>();
        
    }

    init() {

        this.node.js( 'filter-menu' ).click.add( () => {
            this.closeMenu();
        });
        this.node.js( 'close-menu' ).click.add( () => {
            this.closeMenu();
        });

        this.node.all( '.offer-element' ).forEach( offerElement => {
            offerElement.click.add( this.offerClicked, this );
        });

        this.node.js( 'filter-reset' ).click.add( () => {
            this.node.all( '.offer-element' ).forEach( offerElement => {
                offerElement.hasClass('-active') ? offerElement.removeClass('-active') : null;
            });
            this.selected.dispatch( [] );

        });
        
    }

    closeMenu(){
        var offersList = Node.fromNative(this.node.js( 'offers-container' ).native);
        var filterMenu = Node.js('filter-menu');
        filterMenu.toggleClass('-open');
        if (offersList.hasClass("-show")) {
            offersList.removeClass("-show");
            offersList.addClass("-hide");
        } else {
            offersList.removeClass("-hide");
            offersList.addClass("-show");
        }
    }
    
    offerClicked( n:Node ){
        
        n.toggleClass( '-active' );

        var categories = [];
        // search all activated
        var activated = this.node.all( '.offer-element.-active' );
        
        activated.forEach( active => {
            categories.push( active.data.cat );
        });
        
        this.selected.dispatch( categories );

    }
}

export default OfferFilter;

scanner.ioc.add( 'offer-filter', function( n:Node ){
    return new OfferFilter().render( n );
});


