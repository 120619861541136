import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import Lang from 'ln/lang/Lang';

import setup from 'ln/setup/setup';
import { SetupConfig } from 'ln/setup/setup';

import GoogleMapElement from './GoogleMapElement';
import './OfferFilter';
import './OfferDetail';

import './templates/template';
// import './templates/icons';


export var offers;

export function init( config:SetupConfig ) {
	
	setup.init( config );

	Lang.add( config['lang'] );


	Template.context.setup = setup;
	Template.context.imagePath = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
		return decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() );
	}

	
	var map = new GoogleMapElement( setup.data( 'offers' ), config['filter'], config['zoom'] ).render( Node.one( '#google-map'));

}