import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import View from 'ln/view/View';
import { scanner } from 'ln/view/Scanner';
import { mapStyles } from './MapStyles';
import OfferFilter from './OfferFilter';
import OfferDetail from './OfferDetail';
import setup from 'ln/setup/setup';

export interface IGoogleMapElement{
	template:string,
	offers:any,
	offersFiltered:any
}

export interface IOffer{

	category:string,
	location:{ lat:number, lng: number },
	title:string,
	email:string,
	phone:string,
	website:string
}
/**
 * This class renders a chapter element
 */
class GoogleMapElement extends View {
	
	private map;
	private pins:Array<any>;
	private prefilter:string;
	private prezoom:string;
	private zoomLevel:number;
	public offersFiltered;
	public offerDetail;

	constructor( private offers:IOffer[], prefilter:string, prezoom:string ) {

		
		super();
		this.defaultTemplate = 'app.google-map';
		this.prefilter = prefilter;
		this.prezoom = prezoom;
		this.zoomLevel = 8;
				
		this.pins = [];
		this.offersFiltered = [];

	}

	private MapTypeControl(controlDiv, map, mapType) {

		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.classList.add('control-ui');
		controlUI.classList.add('-satellite');
		controlUI.title = 'Karten Typ anpassen auf "' + mapType + '"';
		controlDiv.appendChild(controlUI);

		// Setup the click event listeners: simply set the map to Chicago.
		controlUI.addEventListener('click', function() {
			map.setMapTypeId(mapType);
		});

	}

	private ZoomControl(controlDiv, zoomType) {

		// Set CSS for the control border.
		var controlUI = document.createElement('div');
		controlUI.classList.add('control-ui');
		controlUI.classList.add('-' + zoomType);
		controlUI.title = 'Zoom: "' + zoomType + '"';
		controlDiv.appendChild(controlUI);

	}

	onPinClick( pin ){
		if (pin.getAnimation() !== null) {
			pin.setAnimation(null);
		} else {
			pin.setAnimation(google.maps.Animation.BOUNCE);
		}
	}

	setPins( ){

		this.offers.forEach( offer => {

			var pin = new google.maps.Marker({
				position: offer.location, 
				map: this.map,
				category: offer.category,
				// offer: offer,
				title: offer.title + '\n' + offer.email + '\n' + offer.phone + '\n' + offer.website,
				icon: {
					// url: window.location.href + "/assets/pin-" +  offer.category + ".svg",
					url: setup.route( 'svg', { file_name: 'pin-' + offer.category } ).url(),
					scaledSize: new google.maps.Size(50, 57),
					size: new google.maps.Size(50, 57),
				}
			});

			pin.addListener('click' , pinClicked.bind(this));

			this.pins.push(pin);

			function pinClicked() {
				pin.setAnimation(google.maps.Animation.BOUNCE);
				setTimeout(() => { pin.setAnimation(null); }, 1500); 
				this.offerDetail.setOffer( offer );
			}

		});

	}

	private filterPins( categories:string[] ){

		if( categories.length != 0 ){
			
			var filtered = this.pins.filter( pin => {
				return categories.indexOf( pin.category ) >= 0; 
				//return categories.indexOf( pin.offer.category ) >= 0; 
			});
	
			// remove all pins
			this.pins.forEach( pin => {
				pin.setMap( null );
			})
	
			// set filtered pins
			filtered.forEach( filteredPin => {
				filteredPin.setMap( this.map );
			})

		} else {
			this.pins.forEach( pin => {
				pin.setMap( this.map );
			})
		}
	}

	
	/**
	 * Renders the chapter into its container.
	 */
	public init() {

		// Älggi-Alp > initial map center
		var swissGeoCenter = { lat: 46.798616, lng: 8.231963 };

		scanner.scan( this.node );

		var offerfilter = scanner.first( 'offer-filter' ) as OfferFilter;
		offerfilter.selected.add( this.filterPins, this );

		this.offerDetail = scanner.first( 'offer-detail' ) as OfferDetail;

		// if we got a prezoom via URL we set this one.
		if (this.prezoom !== null){
			this.zoomLevel = parseInt(this.prezoom);
		}


		//this.map = new google.maps.Map(document.getElementById('google-map'), {
		this.map = new google.maps.Map(this.node.js('google-map').native, {
			center: swissGeoCenter,
			zoom: this.zoomLevel,
			mapTypeId: 'roadmap', 
			disableDefaultUI: true,
			minZoom: 7,
			styles: mapStyles
		});


		// Create new Control to change th Map-Type to: Roadmap
		var mapTypeButton = document.createElement('div');
		mapTypeButton.classList.add('map-control', '-roadmap'); 
		var mapType = new this.MapTypeControl(mapTypeButton, this.map, "roadmap");

		mapTypeButton.index = 1;
		this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapTypeButton);

		google.maps.event.addDomListener(mapTypeButton, 'click', () => {
			if (mapTypeButton.classList.contains('-active')) {
				this.map.setMapTypeId('roadmap'); 
				mapTypeButton.classList.remove('-active');
			} else {
				this.map.setMapTypeId('satellite'); 
				mapTypeButton.classList.add('-active');
			}
		});

		this.setPins();

		// Create new Control to Zoom out
		var ZoomOutControlButton = document.createElement('div');
		ZoomOutControlButton.classList.add('map-control', '-zoomout'); 
		var zoomOutControl = new this.ZoomControl(ZoomOutControlButton, "out");

		zoomOutControl.index = 3;
		this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(ZoomOutControlButton);

		google.maps.event.addDomListener(ZoomOutControlButton, 'click', () => {
			this.map.setZoom(this.map.getZoom() - 1);
		});

		// Create new Control to Zoom in
		var ZoomInControlButton = document.createElement('div');
		ZoomInControlButton.classList.add('map-control', '-zoomin'); 
		var zoomInControl = new this.ZoomControl(ZoomInControlButton, "in");

		zoomInControl.index = 4;
		this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(ZoomInControlButton);

		// add event listeners for the Zoom Buttons
		google.maps.event.addDomListener(ZoomInControlButton, 'click', () => {
			this.map.setZoom(this.map.getZoom() + 1);
		});

		// Places Search Input Field 
		var input = this.node.js('places-search').native;
		var searchBox = new google.maps.places.SearchBox(input);
		// input.addEventListener( 'click', function(){
		// 	if(!input.classList.contains('-active')){
		// 		input.classList.add('-active');
		// 		input.classList.remove('-passive');
		// 	}
		// });
		// input.addEventListener( 'blur', function(){
		// 	if(input.classList.contains('-active')){
		// 		input.classList.remove('-active');
		// 		input.classList.add('-passive');
		// 	}
		// });
		//this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(input);
		

		// Bias the SearchBox results towards current map's viewport.
		this.map.addListener('bounds_changed', () => {
			searchBox.setBounds(this.map.getBounds());
		});

		var markers = [];
		// Listen for the event fired when the user selects a prediction and retrieve
		// more details for that place.
		searchBox.addListener('places_changed', () => {
			var places = searchBox.getPlaces();

			if (places.length == 0) {
				return;
			}

			// Clear out the old markers.
			markers.forEach( marker => {
				marker.setMap(null);
			});
			markers = [];

			// For each place, get the icon, name and location.
			var bounds = new google.maps.LatLngBounds();
			places.forEach( place => {
				if (!place.geometry) {
					console.log("Returned place contains no geometry");
					return;
				}
				var icon = {
					url: place.icon,
					size: new google.maps.Size(71, 71),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(17, 34),
					scaledSize: new google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				markers.push(new google.maps.Marker({
					map: this.map,
					icon: icon,
					title: place.name,
					position: place.geometry.location
				}));

				if (place.geometry.viewport) {
					// Only geocodes have viewport.
					bounds.union(place.geometry.viewport);
				} else {
					bounds.extend(place.geometry.location);
				}

				this.map.fitBounds(bounds);
			});
		});	
		
		if (this.prefilter !== null){
			this.filterPins([ this.prefilter ]);
			document.querySelectorAll('[data-cat="' + this.prefilter + '"]')[0].classList.add("-active");
		}
	}

}

export default GoogleMapElement;