import View from 'ln/view/View';
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import { scanner } from 'ln/view/Scanner';

class OfferDetail extends View {

    private offer;

    constructor( ) {

        super(  );

        this.defaultTemplate = 'app.offer-detail';
        
    }

    // set offer
    public setOffer( offer ){
        
        this.offer = offer;
        var offerContainer = Node.fromHTML( Template.render( 'app.offer-container', this.offer));
        this.node.js('offer-container').replace(offerContainer);
        
        this.node.removeClass( '-hide' );
        this.node.addClass( '-show' );

        Node.all('.map-control').forEach( node =>  {
            node.addClass( '-moveleft' );
            node.removeClass( '-moveright' );
        });
    }

    public hide() {
        this.node.removeClass( '-show' );
        this.node.addClass( '-hide' );

        Node.all('.map-control').forEach( node =>  {
            node.removeClass( '-moveleft' );
            node.addClass( '-moveright' );
        });
    }

    init() {

        Node.js( 'close-button' ).mouseenter.add(() => {
            this.node.js("close-button").addClass('-active');
        });

        Node.js( 'close-button' ).mouseleave.add(() => {
            this.node.js("close-button").removeClass('-active');
        });

        Node.js( 'close-button' ).mousedown.add(() => {
            this.node.js("close-button").addClass('-active');
        });

        Node.js( 'close-button' ).mouseup.add(() => {
            this.node.js("close-button").removeClass('-active');
        });

        Node.js( 'close-button' ).click.add(() => {
            this.hide();
        });

    }

}

export default OfferDetail;

scanner.ioc.add( 'offer-detail', function( n:Node ){
    return new OfferDetail().render( n );
});


